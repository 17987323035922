:root {
  --font-family-main: 'Lato', sans-serif;
  --font-family-serif: sans-serif;
  --font-weight-md: 500;
  --font-weight-xxxl: 700;
  --font-size-xxxs: 0.5rem;
  --font-line-xxxs: 1rem;
  --font-xxxs: var(--font-size-xxxs) / var(--font-line-xxxs) var(--font-family-main);
  --font-size-xxs: 0.625rem;
  --font-line-xxs: 1rem;
  --font-xxs-bold: var(--font-weight-xxxl) var(--font-size-xxs) / var(--font-line-xxs)
    var(--font-family-main);
  --font-xxs: var(--font-size-xxs) / var(--font-line-xxs) var(--font-family-main);
  --font-size-xs: 0.75rem;
  --font-line-xs: 1.125rem;
  --font-xs-bold: var(--font-weight-xxxl) var(--font-size-xs) / var(--font-line-xs)
    var(--font-family-main);
  --font-xs: var(--font-size-xs) / var(--font-line-xs) var(--font-family-main);
  --font-size-sm: 0.875rem;
  --font-line-sm: 1.312rem;
  --font-sm: var(--font-size-sm) / var(--font-line-sm) var(--font-family-main);
  --font-sm-bold: var(--font-weight-xxxl) var(--font-size-sm) / var(--font-line-sm)
    var(--font-family-main);
  --font-size-l: 1rem;
  --font-line-l: 1.473rem;
  --font-l: var(--font-size-l) / var(--font-line-l) var(--font-family-main);
  --font-l-semi-bold: var(--font-weight-md) var(--font-size-l) / var(--font-line-l)
    var(--font-family-main);
  --font-l-bold: var(--font-weight-xxxl) var(--font-size-l) / var(--font-line-l)
    var(--font-family-main);
  --font-size-m: 1.125rem;
  --font-line-m: 1.687rem;
  --font-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);
  --font-m-semi-bold: var(--font-weight-md) var(--font-size-m) / var(--font-line-m)
    var(--font-family-main);
  --font-m-bold: var(--font-weight-xxxl) var(--font-size-m) / var(--font-line-m)
    var(--font-family-main);
  --font-size-xl: 1.25rem;
  --font-line-xl: 1.875rem;
  --font-xl: var(--font-size-xl) / var(--font-line-xl) var(--font-family-main);
  --font-xl-bold: var(--font-weight-xxxl) var(--font-size-xl) / var(--font-line-xl)
    var(--font-family-main);
  --font-xl-semi-bold: var(--font-weight-md) var(--font-size-xl) / var(--font-line-xl)
    var(--font-family-main);
  --font-size-xxl-lg: 1.5rem;
  --font-line-xxl-lg: 2.25rem;
  --font-xxl: var(--font-size-xxl-lg) / var(--font-line-xxl-lg) var(--font-family-main);
  --font-xxl-semi-bold: var(--font-weight-md) var(--font-size-xxl-lg) / var(--font-line-xxl-lg)
    var(--font-family-main);
  --font-xxl-lg: var(--font-size-xxl-lg) / var(--font-line-xxl-lg) var(--font-family-main);
  --font-xxl-bold: var(--font-weight-xxxl) var(--font-size-xxl-lg) / var(--font-line-xxl-lg)
    var(--font-family-main);
  --font-size-xxl-l: 1.8rem;
  --font-line-xxl-l: 2.35rem;
  --font-xxl-l: var(--font-size-xxl-l) / var(--font-line-xxl-l) var(--font-family-main);
  --font-xxl-l-semi-bold: var(--font-weight-md) var(--font-size-xxl-l) / var(--font-line-xxl-l)
    var(--font-family-main);
  --font-xxl-l-bold: var(--font-weight-xxxl) var(--font-size-xxl-l) / var(--font-line-xxl-l)
    var(--font-family-main);
  --font-size-xxl: 1.9rem;
  --font-line-xxl: 2.55rem;
  --font-xxl-sd: var(--font-size-xxl) / var(--font-line-xxl) var(--font-family-main);
  --font-xxl-sd-semi-bold: var(--font-weight-md) var(--font-size-xxl) / var(--font-line-xxl)
    var(--font-family-main);
  --font-size-xxxl: 2.125rem;
  --font-line-xxxl: 3.187rem;
  --font-xxxl: var(--font-weight-xxxl) var(--font-size-xxxl) / var(--font-line-xxxl)
    var(--font-family-main);
}
