.ant-modal-content {
  padding: 0 !important;
  height: 100% !important;
  border: 1px solid var(--gray-bg-light);
}

.ant-modal-body {
  display: flex;
  align-items: center;
  height: 100% !important;
}
