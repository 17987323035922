.dateWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.root_white {
  width: 100%;
}

.root_primary {
  width: 100%;
  background: var(--primary) !important;
  border: var(--border-md) solid var(--gray-dark) !important;
  border-radius: var(--border-r-3) !important;

  span {
    background: var(--primary) !important;
  }

  * {
    color: var(--white) !important;
  }
  *::placeholder {
    color: var(--primary-disabled) !important;
  }
}

.dropdown_primary {
  div {
    background: var(--primary) !important;
    border-radius: var(--border-r-3) !important;
  }

  * {
    color: var(--white) !important;
  }
  *::placeholder {
    color: var(--primary-disabled) !important;
  }
}

.error {
  font: var(--font-sm);
  color: var(--error);
}
