:root {
  //std colors
  --white: #fff;
  --red: #ff5b6e;
  --primary-text: #1e1e1e;
  --white-text: #fdfcfc;
  --background: #fdfcfc;
  //status colors
  --launched-bg: #aff4c6a1;
  --launched-text: #39ac6f;
  --green-dark-rocket: #197444;
  --not-launched-bg: #ffcd295e;
  --not-launched-text: #af792d;
  --ended-bg: #cae7fd;
  --done-text: #1a7fc9;

  //main
  --primary: #1e1e1e;
  --secondary: #cdced1;
  --accent: #ff0000;
  --tertiary: #5d466d;
  --light: #fbfbf3;
  --not-launched: #cbe80e;
  --ended: #34f716;
  --launched: #2075f5;
  --blue: #1f4ae1;
  --dark: #00000028;
  --gray: #ecedf5;
  --gray-dark-track: #73758b;
  --gray-dark-range: #464646;
  --success: #cfbe50;
  --error: var(--red);
  --info: #c69eba;
  --logo-red: rgba(255, 64, 64, 1);
  --logo-red-opacity: rgba(255, 64, 64, 0.3);

  //main-hover
  --primary-hover: #393939;
  --secondary-hover: #d4d5d8;
  --tertiary-hover: #523d5e;
  --white-hover: #e0e0e0;

  //main-disabled
  --primary-disabled: #bababa;
  --secondary-disabled: #eaebed;
  --tertiary-disabled: #b185d0;
  --white-disabled: #ffffff77;

  //gradients
  --green-light: #ccce7b;
  --green-medium: #aec082;
  --green-dark: #7d9a68;
  --green-dark-bg: #b5d7ce;
  --green-primary: #14ae5c;
  --gray-light: #e5e5e5;
  --gray-medium: #a7a8ab;
  --gray-dark: #777;
  --pink-light: #fff0f5;
  --orange: #ffa500;
  --pink-medium: #e4728f;
  --pink-dark: #b75f80;
  --progress-dark-gray: #73768b;

  //devider
  --devider-gray: #73768b;

  //border
  --border-gray: #e7e6ef;

  //text
  --gray-text: #5b5b5c;

  //bg
  --green-bg-dark: rgba(47, 122, 102, 0.53);
  --green-bg-light: rgba(115, 180, 163, 0.53);
  --gray-bg-light: var(--gray-light);
  --bg-light: #f3f3f3;
  --border-alert: rgba(0, 0, 0, 0.28);
  --gray-bg-dark: #cdcfdf;

  //select
  --green-bg-select: rgba(115, 180, 163);

  //input
  --input-disabled: #c2c1d2;
  --input-bg: #ecedf5;

  //btn
  --shadow-btn: 0px 3px 3px #00000028;
  --green-btn-dark: rgba(47, 122, 102);

  //circle
  --fourth-circle: #ECECF2;
  --third-circle: #EBEAF1;
  --second-circle: #E1E4ED;
  --first-circle: #E1E3EF;
  --core-circle: #C6CCE0;

}
