@import 'src/styles/breakpoints';

.layout {
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  margin: 4vh 2vw;
  background: var(--gray) url('../../../../public/assets/bg-add-company.svg');
  background-size: 40%;
  height: 313px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font: var(--font-xxl);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;

  @include media('>=md') {
    background-size: 10%;
    height: auto;
    margin: var(--space-10) 0;
    padding: var(--space-xxl) 0;
  }
}

.text {
  margin-bottom: var(--space-5);

  font: var(--font-xl-bold);

  @include media('>=md') {
    font: var(--font-xxxl);
    margin-bottom: var(--space-9);
  }
}

.blockButtons {
  display: flex;
  flex-direction: row;
  gap: var(--space-1);
}

.circles {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:before,
  &:after {
    content: none;
    position: absolute;
    width: 95px;
    height: 95px;
    border-radius: 100px;
    border: 1px dashed var(--core-circle);
  }

  @include media('>=sm') {
    &:before,
    &:after {
      width: 115px;
      height: 115px;
    }
  }

  @include media('>=xl') {
    &:before,
    &:after {
      width: 148px;
      height: 148px;
    }
  }
}

.core {
  width: 95px;
  height: 95px;
  background-color: var(--core-circle);
  border-radius: 100px;

  &:before {
    left: -98px;
  }

  &:after {
    right: -98px;
  }

  &:before,
  &:after {
    content: '';
    background-color: var(--first-circle);
  }

  @include media('>=sm') {
    width: 115px;
    height: 115px;

    &:before {
      left: -126px;
    }

    &:after {
      right: -126px;
    }
  }

  @include media('>=xl') {
    width: 148px;
    height: 148px;

    &:before {
      left: -162px;
    }

    &:after {
      right: -162px;
    }
  }
}

.secondLine {
  @include media('>=sm') {
    &:before,
    &:after {
      content: '';
      background-color: var(--first-circle);
    }

    &:before {
      left: -252px;
    }

    &:after {
      right: -252px;
    }
  }

  @include media('>=md') {
    &:before,
    &:after {
      background-color: var(--second-circle);
    }
  }

  @include media('>=xl') {
    &:before {
      left: -324px;
    }

    &:after {
      right: -324px;
    }
  }
}

.thirdLine {
  @include media('>=md') {
    &:before,
    &:after {
      content: '';
      background-color: var(--third-circle);
    }

    &:before {
      left: -378px;
    }

    &:after {
      right: -378px;
    }
  }

  @include media('>=xl') {
    &:before {
      left: -486px;
    }

    &:after {
      right: -486px;
    }
  }
}

.fourthLine {
  @include media('>=md') {
    &:before,
    &:after {
      content: '';
      background-color: var(--fourth-circle);
    }

    &:before {
      left: -504px;
    }

    &:after {
      right: -504px;
    }
  }

  @include media('>=xl') {
    &:before {
      left: -648px;
    }

    &:after {
      right: -648px;
    }
  }
}
