@import '../../../../styles/breakpoints.scss';

.percentText {
  white-space: nowrap;
  font: var(--font-sm);

  @include media('>=sm') {
    font: var(--font-m);
  }
}
