:root {
  //main
  --space-1: 0.5rem;
  --space-2: 0.75rem;
  --space-3: 1rem;
  --space-4: 1.25rem;
  --space-5: 1.5rem;
  --space-6: 1.75rem;
  --space-7: 2rem;
  --space-8: 2.5rem;
  --space-9: 3rem;
  --space-10: 4rem;
  --space-11: 2.75rem;
  --space-12: 5rem;

  //formatted
  --space-xxs: var(--space-1);
  --space-xs: var(--space-2);
  --space-sm: var(--space-3);
  --space-md: var(--space-5);
  --space-lg: var(--space-7);
  --space-xl: var(--space-9);
  --space-xxl: var(--space-10);
  --space-xxxl: var(--space-12);
}
