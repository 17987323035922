.root {
  width: 100%;
  margin-top: var(--space-md);
  padding: var(--space-md);
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
}

.warning {
  text-align: center;
  font: var(--font-l);
}

.btnsBlock {
  display: flex;
  justify-content: space-between;
}