@import '../../../styles/breakpoints.scss';

.root {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 5px;
  border-radius: 60px;
  padding: 5px 10px;
  font: var(--font-xxs-bold);
  white-space: nowrap;
  width: min-content;

  @include media('>sm') {
    font: var(--font-xs-bold);
    padding: 6px 14px;
    gap: 10px;
  }

  @include media('>md') {
    font: var(--font-xs);
  }

  @include media('>lg') {
    font: var(--font-sm-bold);
    gap: 10px;
  }

  @include media('>xl') {
    padding: 10px 20px;
    font: var(--font-l-bold);
  }
}

.LAUNCHED {
  background-color: var(--launched-bg);
  color: var(--green-dark-rocket);
  border: 1px solid var(--launched-text);
}

.NOT_LAUNCHED {
  background-color: var(--not-launched-bg);
  color: var(--not-launched-text);
  border: 1px solid var(--not-launched-text);
}

.APPROVED {
  background-color: var(--not-launched-bg);
  color: var(--not-launched-text);
  border: 1px solid var(--not-launched-text);
}

.ENDED {
  background-color: var(--ended-bg);
  color: var(--done-text);
  border: 1px solid var(--done-text);
}

.PART {
  background-color: var(--launched-bg);
  color: var(--launched-text);
  border: 1px solid var(--launched-text);
}

.NONE {
  background-color: var(--not-launched-bg);
  color: var(--not-launched-text);
  border: 1px solid var(--not-launched-text);
}

.FULL {
  background-color: var(--ended-bg);
  color: var(--done-text);
  border: 1px solid var(--done-text);
}

.AVAILABLE {
  background-color: var(--ended-bg);
  color: var(--done-text);
  border: 1px solid var(--done-text);
}

.NOT_AVAILABLE {
  background-color: var(--not-launched-bg);
  color: var(--not-launched-text);
  border: 1px solid var(--not-launched-text);
}

.NEEDS_APPROVE {
  background-color: var(--not-launched-bg);
  color: var(--not-launched-text);
  border: 1px solid var(--not-launched-text);
}

.icon {
  color: inherit;
  width: 15px;
  height: 15px;

  @include media('>sm') {
    width: 17px;
    height: 17px;
  }

  @include media('>xl') {
    width: 25px;
    height: 25px;
  }
}
