@import '../../../../../styles/breakpoints.scss';

.root {
  width: 100%;
  position: relative;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
}

.table {
  width: 240px;
  overflow: hidden;
  overflow-y: scroll;
  margin: 0 auto;

  @include media('>=sm') {
    width: 500px;
  }

  @include media('>=md') {
    width: 100%;
  }
}

.icon {
  width: 25px;
}

.iconAndName {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 14px;
  height: 100px;
}

.videoWrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 10px;
  width: 80%;
}

.videoAdv {
  width: 157px;
  border-radius: 10px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.34) 0%, rgba(0, 0, 0, 0.34) 100%);
}

.smallText {
  font: var(--font-l);
  word-break: break-word;
}

.textNone {
  display: flex;
  width: 100%;
  height: 60vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.primaryText {
  font: var(--font-xxl);
}

.numbers {
  font: var(--font-xl);
}

.wrapper {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 10px;
}

.eye {
  width: 20px;
}

.textWithEye {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 5px;
  align-items: center;
}
