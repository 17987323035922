.item {
  display: inline-block;
  width: 100%;
  color: var(--primary);
  padding: var(--space-xxs) var(--space-sm);
  cursor: pointer;
  transition: all 0.3s;
  font: var(--font-m);

  @media (any-hover: hover) {
    &:hover {
      &:not(:disabled) {
        color: var(--red);
      }
    }
  }
}
.iconAndText {
  width: 100%;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 5px;
  color: inherit;
  width: 20px;
  height: 20px;
}

.item_active {
  color: var(--red);
}
