.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: var(--green-bg-light);
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before {
  background: var(--green-bg-light);
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before {
  background: var(--green-bg-light);
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(
    .ant-picker-cell-range-start-single
  )::before {
  background: var(--primary-hover);
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(
    .ant-picker-cell-range-start-single
  )::before {
  background: var(--primary-hover);
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(
    .ant-picker-cell-range-start-single
  )::before {
  background: var(--primary-hover);
}

.ant-picker-cell-inner::after {
  background-color: var(--primary-hover) !important;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ).ant-picker-cell-range-hover-start::before {
  background-color: var(--primary-hover) !important;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ).ant-picker-cell-range-hover-end::before {
  background-color: var(--primary-hover) !important;
}
