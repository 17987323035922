.root {
  width: 100%;
}

.btnWrapper {
  margin-bottom: 15px;
}

.row {
  width: 100%;
  cursor: pointer;
  background-color: var(--white);
}

.row_active {
  background: var(--pink-light);
}

.clearFilters {
  margin-bottom: var(--space-md);
}


