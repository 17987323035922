@import 'src/styles/breakpoints';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-heght-mobile);
  background: var(--white);
  margin: 0;
  position: relative;
  padding: 10px 16px;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    background: var(--gray-medium);
  }

  @include media('>=sm') {
    padding: 20px 26px;
    height: var(--header-heght-tablet);
  }

  @include media('>=md') {
    padding: 16px 90px;
    height: auto;
  }

  @include media('>=xl') {
    padding: 23px 107px;
    height: var(--header-heght);
  }
}

.zIndexWithModal {
  z-index: 1000000;
}

.logo {
  width: 70px;
  height: 21px;

  @include media('>=sm') {
    width: 130px;
    height: 40px;
  }

  @include media('>=md') {
    height: 45px;
  }

  @include media('>=xl') {
    width: 164px;
    height: 50px;
  }
}

.wrapper {
  display: flex;
  gap: var(--space-9);
  align-items: center;
  justify-content: center;

  @include media('>=md') {
    gap: var(--space-7);
  }
}

.userInfoWrapper {
  display: flex;
  flex-direction: row;
  gap: var(--space-1);
}

.photoWrapper {
  display: none;

  @include media('>=sm') {
    display: flex;
    background-color: var(--accent);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }

  @include media('>=md') {
    width: 45px;
    height: 45px;

    img {
      height: 30px;
    }
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: var(--white);
}

.role {
  display: none;

  @include media('>=sm') {
    display: inline;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
  }

  @include media('>=md') {
    font-size: 14px;
    line-height: 21px;
  }
}

.name {
  display: none;

  @include media('>=sm') {
    display: inline;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  @include media('>=md') {
    font-size: 12px;
    font-weight: 400;
  }
}

.linkAddCompany {
  display: none;

  @include media('>=sm') {
    display: flex;
  }
}

.addCompany {
  padding: 16px;
}

.burger {
  position: relative;
  border: none;
  width: 37px;
  height: 3px;
  background: var(--primary);
  box-shadow: 0 -12px var(--primary);
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 12px;
    right: 0;
    width: 37px;
    height: 3px;
    background: var(--primary);
  }
}

.modalWrapper {
  position: absolute !important;
  top: var(--header-heght-mobile) !important;
  border: none;

  width: 100% !important;
  right: 20px !important;

  @include media('>=sm') {
    top: var(--header-heght-tablet) !important;
    width: 533px !important;
    right: 60px !important;
  }

  @include media('>=xl') {
    top: var(--header-heght) !important;
    right: 60px !important;
    width: 533px !important;
  }
}
.navLinks {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.navBarWrapper {
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: flex-start;
}

.userName {
  padding: var(--space-xxs) var(--space-sm);
  font: var(--font-m);
}

.iconAndText {
  width: 100%;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 5px;
  color: inherit;
}

.iconBtn {
  width: 24px;
  height: 24px;
}

.logOutBtn {
  transition: all 0.3s;

  @media (any-hover: hover) {
    &:hover {
      &:not(:disabled) {
        color: var(--red);
      }
    }
  }
}
