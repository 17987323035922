@import '../../../../../styles/breakpoints.scss';

.root {
  display: grid;
  width: 100%;
  grid-template-rows: auto 1fr auto;

  @include media('>=lg') {
    grid-template-rows: auto 1fr auto;
  }
}

.generalStats {
  padding: var(--space-xxl) 0px;
  position: relative;
  display: grid;
  grid-template-rows: repeat(4, auto);
  gap: var(--space-md);

  @include media('>=sm') {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
  }

  @include media('>=md') {
    grid-template-columns: repeat(4, 1fr);
  }

  &::after {
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: var(--primary);
    opacity: 0.2;
    width: 100%;
  }
}

.statsBlock {
  width: 100%;
  background-color: var(--gray);
  border-radius: 8px;
  padding: var(--space-sm);
}

.smallText {
  font: var(--font-sm);
}

.primaryText {
  font: var(--font-m-bold);
  @include media('>=sm') {
    font: var(--font-xl-bold);
  }
}

.diagram {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: 220px;

  @include media('>=xl') {
    max-width: 280px;
  }
}
.textNone {
  display: flex;
  width: 100%;
  height: 60vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.noneDiagram {
  border-radius: 100%;
  border: 1px dashed var(--primary);
}

.cardPeople {
  position: relative;
  border-radius: 10px;
  border: 1px solid var(--border-gray);
  padding: var(--space-md);
  gap: var(--space-sm);
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;

  @include media('>=sm') {
    gap: 0;
    grid-template-columns: auto auto;
    padding: var(--space-xl);
  }

  @include media('>=md') {
    gap: 0;
    grid-template-columns: auto auto;
  }
}

.cardAge {
  position: relative;
  border-radius: 10px;
  border: 1px solid var(--border-gray);
  padding: var(--space-md);
  width: 100%;
  height: min-content;

  @include media('>=md') {
    padding: var(--space-xl);
    gap: 0;
    grid-template-columns: auto auto;
  }
}

.statsAuditory {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--space-xl);
  padding: var(--space-xxl) 0px;

  @include media('>=md') {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
  }

  &::after {
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: var(--primary);
    opacity: 0.2;
    width: 100%;
  }
}

.cardAndTitle {
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: var(--space-md);

  @include media('>=sm') {
    gap: var(--space-xl);
  }
}

.titleText {
  font: var(--font-xl-bold);

  @include media('>=sm') {
    font: var(--font-xxl-l-semi-bold);
  }
}

.people {
  ul {
    list-style-type: disc;
  }
}

.textMan {
  font: var(--font-sm);

  @include media('>=sm') {
    font: var(--font-m);
  }
}
.textWoman {
  margin-top: 10px;
  color: var(--red);
  font: var(--font-sm);

  @include media('>=sm') {
    font: var(--font-m);
  }
}

.wrapperBar {
  width: 60%;

  @include media('>=md') {
    width: 40%;
  }
}

.progressBar {
  height: 8px !important;

  @include media('>=md') {
    height: 6px !important;
  }
}

.progressBarPercents {
  font: var(--font-sm);
  min-width: 55px;
  text-align: right;

  @include media('>=md') {
    font: var(--font-l);
  }
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.listElement {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-2);
}

.statView {
  position: relative;
  display: grid;
  padding: var(--space-xxl) 0px;
  grid-template-rows: auto 1fr;
  gap: var(--space-xl);
  &::after {
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: var(--primary);
    opacity: 0.2;
    width: 100%;
  }
}

.statHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardView {
  position: relative;
  width: 100%;
  height: 60vh;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid var(--border-gray);
  padding: var(--space-xs);
}

.diagramLegend {
  display: flex;
  justify-content: center;
}

.diagramView {
  right: 0;
  position: absolute !important;
  width: 800px !important;

  @include media('>=lg') {
    width: 100% !important;
  }
}
