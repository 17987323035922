@import 'src/styles/breakpoints';

.mainLayout {
  min-height: 100vh;
  width: 100vw;
  background: var(--white) !important;
}

.layout {
  background: var(--white) url('../../../../../public/assets/bg-pattern.svg') 80% 80%;
  height: calc(100vh - var(--header-heght-mobile));
  padding: var(--space-sm) var(--space-xs);

  @include media('>sm') {
    padding: var(--space-lg) var(--space-xl);
    height: var(--header-heght-tablet);
  }

  @include media('>=md') {
    padding: var(--space-lg) var(--space-xxl);
  }

  @include media('>=xl') {
    padding: var(--space-lg) var(--space-xxl);
    height: var(--header-heght);
  }
}

.content {
  height: 100%;
  overflow: scroll;
  background: var(--background);
  border: 1px solid var(--border-gray);
  border-radius: 20px;
  display: grid;
  grid-template-rows: 1fr auto;
}
