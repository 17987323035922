@import '../../../styles/breakpoints.scss';

.root {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: var(--space-xl);

  @include media('>=sm') {
    gap: var(--space-xxl);
  }
}

.statsWrapper {
  padding: var(--space-lg) var(--space-xs);

  @include media('>=sm') {
    padding: var(--space-xl) var(--space-lg);
  }
}

.infoAboutCompany {
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  gap: var(--space-lg);
  grid-template-areas:
    'video'
    'info'
    'diogram';
  grid-template-rows: 1fr auto auto;

  @include media('>=sm') {
    grid-template-areas:
      'video video'
      'info diogram';
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr auto;
  }

  @include media('>=md') {
    grid-template-areas: 'video info diogram';
    grid-template-columns: 45% 23% auto;
    grid-template-rows: auto;
    gap: var(--space-xxxl);
    align-items: center;
  }

  @include media('>=lg') {
    grid-template-areas: 'video info diogram';
    grid-template-columns: 46% 24% auto;
    grid-template-rows: auto;
    gap: var(--space-xxxl);
    align-items: center;
  }
}

.textNameComp {
  word-wrap: break-word;
  font: var(--font-m-bold);
  @include media('>=md') {
    padding: var(--space-xxs) 0 var(--space-xs) 0;
  }

  @include media('>=lg') {
    font: var(--font-xxl-bold);
  }

  @include media('>=xl') {
    font: var(--font-xxl-bold);
  }
}

.textPan {
  font: var(--font-sm);

  @include media('>=md') {
    font: var(--font-xl);
  }
}

.noneDiagram {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px dashed var(--primary);
  aspect-ratio: 1/1;
  @include media('>=xl') {
    width: 250px;
    height: 250px;
  }
}
.videoWrapper {
  grid-area: video;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  @include media('>=xl') {
    align-items: center;
  }
}

.videoBlock {
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.pause {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

.video {
  border-radius: 10px;
  max-width: 720px;
  max-height: 160px;
  background: var(--primary);

  @include media('>=sm') {
    max-height: 348px;
  }

  @include media('>=xlg') {
    max-width: 740px;
    max-height: 420px;
  }

  @include media('>=xl') {
    max-width: 840px;
  }
}

.stub {
  width: 100%;
  height: 100%;
  min-height: 160px;
  min-width: 320px;
  padding: 5%;

  @include media('>=sm') {
    min-height: 368px;
    min-width: 568px;
  }

  @include media('>=xlg') {
    min-width: 740px;
    min-height: 480px;
  }

  @include media('>=xl') {
    min-width: 840px;
  }
}

.textView {
  width: 100%;
}

.statusBtn {
  display: flex;
  align-items: center;
  @include media('>=sm') {
    width: min-content;
  }
}

.card {
  grid-area: info;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: var(--space-xs);
  padding: var(--space-md) 0;
  width: 100%;

  @include media('>=sm') {
    padding: 0;
  }
}

.mediumText {
  font: var(--font-sm);
}

.largeText {
  font: var(--font-m-bold);

  @include media('>=sm') {
    font: var(--font-xl-semi-bold);
  }
}

.timeCompany {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: var(--space-sm);

  border: 1px solid var(--border-gray);
  border-radius: 8px;
  padding: var(--space-sm) var(--space-sm) var(--space-md) var(--space-sm);
  background-color: var(--gray);

  @include media('>=sm') {
    gap: var(--space-xl);
  }

  @include media('>=xl') {
    font: var(--font-xl);
    max-width: 408px;
  }
}

.time {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.textInDiagram {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.diagram {
  width: 100%;
  grid-area: diogram;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.diаgramWrapper {
  position: relative;
  width: 210px;
  height: 210px;
  @include media('>=xlg') {
    width: 250px;
    height: 250px;
  }
}

.percent {
  text-align: center;
  font: var(--font-xxl);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.percent_low {
  color: var(--orange);
}

.percent_ok {
  color: var(--primary);
}

.percent_high {
  color: var(--green-primary);
}

.viewText {
  margin-top: 10px;
  display: flex;
  align-items: center;
  font: var(--font-sm);
  font-weight: 400;

  @include media('>=sm') {
    font: var(--font-md);
  }
}

.icon {
  width: 20px;
  margin-right: 5px;

  @include media('>=xl') {
    width: 32px;
  }
}
