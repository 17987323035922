@import '../../../styles/breakpoints.scss';
.root {
  color: var(--primary-text);
}

.root_h1 {
  font: var(--font-xxxl);
}

.root_h2 {
  font-family: Inter, sans-serif;
  color: var(--primary);
  font: var(--font-xxl);
}

.root_h3 {
  font-family: Inter, sans-serif;
  color: var(--primary);
  font: var(--font-xl);
}

.root_title {
  font: var(--font-l);
  color: var(--primary);
}

.root_primarySelect {
  font-family: Inter, sans-serif;
  color: var(--primary);
  font: var(--font-m);
}

.root_secondarySelect {
  font: var(--font-sm);
  color: var(--white);
  padding: 10px;
}

.root_error {
  font: var(--font-sm);
  color: var(--red);
}
