@import '../../../styles/breakpoints.scss';
.footer {
  padding: var(--space-sm);
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--background);
  height: 113px;

  @include media('>=sm') {
    padding: var(--space-md) var(--space-lg);
    height: auto;
  }

  &::after {
    content: '';
    margin: 0px var(--space-sm);
    width: calc(100% - (var(--space-sm) * 2));
    height: 1px;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    background: var(--gray-medium);
    z-index: 100;

    @include media('>=sm') {
      margin: 0px var(--space-lg);
      width: calc(100% - (var(--space-sm) * 2));
    }
  }
}

.footerWrapper {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  @include media('>=sm') {
    width: 30%;
    flex-direction: row;
  }
}

.text {
  font: var(--font-sm);

  @include media('>=xlg') {
    font: var(--font-xs);
  }
}
