.progressBar {
  width: 100%;

  span {
    display: block;
  }
}

.bar {
  outline: 1px solid var(--border-gray);
  outline-offset: -1px;
  position: relative;
  border-radius: 8px;
  background: var(--gray);
  height: 30px;
  box-sizing: border-box;
}

.progress {
  position: absolute;
  background: var(--progress-dark-gray);
  color: var(--white);
  padding: 5px 0;
  height: 30px;
  text-align: center;
  border-radius: 8px;
}

.progress_empty {
  position: relative;
  margin: 0 auto;
  color: var(--black);
}
