@import '../../../../styles/breakpoints.scss';
.ant-tabs-nav-wrap {
  height: fit-content !important;
}

.ant-tabs-nav {
  height: fit-content !important;
  width: 100% !important;
  background-color: var(--background);
  margin: 0 !important;
  z-index: 100 !important;
}
.ant-tabs-nav-list {
  align-items: stretch !important;
}
.ant-tabs-tab {
  color: var(--gray-dark);
  font: var(--font-sm-bold) !important;
  justify-content: center !important;
  margin: 0 !important;
  word-wrap: break-word !important;
  padding: var(--space-xxs) 0px var(--space-xxs) 0px !important;
  white-space: pre-wrap !important;
  @include media('>=sm') {
    font: var(--font-xl-bold) !important;
  }
  @include media('>=xl') {
    white-space: nowrap !important;
  }
}

.ant-tabs-tab-btn {
  padding: 0rem var(--space-md) !important;
  text-align: center !important;

  @include media('>=sm') {
    padding: 0rem var(--space-lg) !important;
  }
}

.ant-tabs-tab-active {
  color: var(--primary) !important;
  border: 1px solid var(--gray) !important;
  border-bottom-color: transparent !important;
  border-radius: 6px !important;
  background: var(--gray) !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary) !important;
}

.ant-tabs .ant-tabs-tab {
  background: rgba(255, 255, 255, 0);
}
