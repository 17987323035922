@import 'colors';
@import 'borders';
@import 'spaces';
@import 'typography';

:root {
  //Sizes
  --header-heght: 102px;
  --header-heght-tablet: 79px;
  --header-heght-mobile: 60px;
  --sidebar-width: 300px;
}
