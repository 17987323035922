@import '../../../styles/breakpoints.scss';

.root {
  border: none;
  cursor: pointer;
  background: var(--primary);
  box-shadow: var(--shadow-btn);
  color: var(--white);
  border-radius: var(--border-r-10-px);
  transition: all 0.3s;
  font: var(--font-m);
  padding: var(--space-xxs) var(--space-sm);
  height: 51px;

  @include media('>=md') {
    height: auto;
    padding: 10px 16px;
  }
}

.root_withPlus {
  position: relative;
  padding: var(--space-xxs) var(--space-sm) var(--space-xxs) var(--space-11);

  &:before {
    content: '';
    position: absolute;
    top: 25px;
    left: 16px;
    width: 18px;
    height: 2px;
    background: var(--white);
  }

  &:after {
    content: '';
    position: absolute;
    left: 16px;
    bottom: 24px;
    width: 18px;
    height: 2px;
    background: var(--white);
    transform: rotate(90deg);
  }

  @include media('>=md') {
    padding: 10px var(--space-sm) 10px var(--space-11);

    &:before {
      top: 18px;
      width: 14px;
      height: 1.5px;
    }

    &:after {
      bottom: 19px;
      width: 14px;
      height: 1.5px;
    }
  }

  @include media('>=xl') {

    &:before {
      top: 25px;
      width: 14px;
      height: 1.5px;
    }

    &:after {
      bottom: 19px;
      width: 14px;
      height: 1.5px;
    }
  }
}

.root_withArrow {
  padding: 0;
  width: min-content;
  height: min-content;
}

.icon_primary {
  color: var(--white-text);
  width: 25px;
}

.icon_secondary {
  color: var(--primary-text);
  width: 25px;
}

.icon_left {
  margin-right: 10px;
}

.root_primary {
  background: var(--primary);
  color: var(--white-text);
  &:disabled {
    pointer-events: none;
    background: var(--primary-disabled);
    color: var(--white-text);
  }

  @media (any-hover: hover) {
    &:hover {
      &:not(:disabled) {
        background: var(--primary-hover);

        .icon_primary {
          fill: var(--primary-hover);
        }
      }
    }
  }

  &:active {
    &:not(:disabled) {
      background: var(--primary-hover);
    }
  }
}

.root_secondary {
  background: var(--secondary);
  color: var(--primary-text);
  &:disabled {
    pointer-events: none;
    background: var(--secondary-disabled);
    color: var(--gray-medium);
  }

  @media (any-hover: hover) {
    &:hover {
      &:not(:disabled) {
        background: var(--secondary-hover);
      }
    }
  }

  &:active {
    &:not(:disabled) {
      background: var(--secondary-hover);
    }
  }
}

.root_link {
  border: 1px solid transparent;
  color: var(--gray-dark);
  font: var(--font-l);
  background: transparent;
  box-shadow: none;
  border-radius: unset;
  padding: 0;

  @media (any-hover: hover) {
    &:hover {
      border-bottom: var(--border-md) solid var(--primary);
    }
  }

  &:disabled {
    pointer-events: none;
  }
}

.root_transparent {
  background-color: transparent;
  color: var(--primary);
  box-shadow: none;
  border-radius: unset;
}

.text {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font: var(--font-sm);

  @include media('>=md') {
    white-space: nowrap;
  }
}

.root_burgerBtn {
  height: 38px;
  padding: 7px;
  background: var(--gray);
  box-shadow: none;

  @include media('>=sm') {
    height: 40px;
    padding: 8px 10px;
  }

  @include media('>=md') {
    height: auto;
    padding: 10px;

    svg {
      height: 20px;
    }
  }

  @include media('>=xl') {
    height: 48px;
    padding: 12px 16px;
  }
}

.root_spinner {
  width: 20px;
  margin-right: 10px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background:
    linear-gradient(0deg, rgb(0 0 0/50%) 30%, #0000 0 70%, rgb(250 250 250/100%) 0) 50%/8% 100%,
    linear-gradient(90deg, rgb(250 250 250/25%) 30%, #0000 0 70%, rgb(250 250 250/75%) 0) 50%/100%
      8%;
  background-repeat: no-repeat;
  animation: s3 1s infinite steps(12);

  &:before,
  &:after {
    content: '';
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
  }

  &:after {
    opacity: 0.83;
    transform: rotate(60deg);
  }
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}
