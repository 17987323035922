:root {
  //weight
  --border-light: 0.5px;
  --border-md: 1px;
  --border-bold: 2px;

  //radius
  --border-r-1: 0.25rem;
  --border-r-2: 0.5rem;
  --border-r-3: 0.75rem;
  --border-r-4: 1rem;
  --border-r-10-px: 10px;
}
