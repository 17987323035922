.appVersion {
  position: absolute;
  bottom: -40px;
  left: 0;
  width: 50px;
  background-color: var(--background);
  border: 1px solid var(--border-gray);
  border-radius: 5px 5px 0 0;
  cursor: default;
  transition: ease-in-out 0.3s;

  svg {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 32px;
    color: var(--gray-dark-track);
    transition: ease-in-out 0.3s;
  }

  &:hover {
    transform: translateY(-40px);

    svg {
      transform: rotateX(180deg);
    }
  }
}

.appVersionContent {
  padding: var(--space-2) 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
