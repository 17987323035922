* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input,
button,
textarea,
select {
  margin: 0;
  font: inherit;
}

ul {
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  height: 100vh;
  overflow: hidden;
}