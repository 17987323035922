@import 'reset';
@import 'variables';
@import 'utils';
@import 'src/styles/breakpoints';

.App {
  margin: 0 auto;
  height: 100vh;
}

.content-page {
  display: flex;
  justify-content: center;
}

html {
  font-size: 16px;

  @include media('>=md') {
    font-size: 14px;
  }

  @include media('>=xl') {
    font-size: 20px;
  }
}
